import styled, { css } from 'styled-components';

export const AnimationContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    min-width: 101%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;

    ${({ responsive }) => responsive
    && css`
    width: 100% !important;
    height: auto !important;
    `}
  }
`;
