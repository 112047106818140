import React from 'react';
import BackgroundAnimation from 'src/components/mobile/backgroundAnimation/backgroundAnimation';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import webm from 'src/assets/videos/chapter_3/sub_2/3-2-2__mobile.webm';
import mp4 from 'src/assets/videos/chapter_3/sub_2/3-2-2__mobile.mp4';
import { graphql, useStaticQuery } from 'gatsby';
import { getLangFromPath } from 'src/utils/index.js';
import { BlockContainer } from '../../_styles';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query, audio = 'Stanisław Stroński' }) => {
  const assetQuery = useStaticQuery(graphql`
    query {
      audioImage: file(relativePath: { eq: "chapter_3/sub_2/3-2-2-audio.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
    }
    `);
  return (
    <BlockContainer height="1100px">
      <BackgroundAnimation src={webm} mp4={mp4}>
        <TextAnimation containerStyle={{ height: '30%', marginTop: '10rem' }} dark body={query.mdx.body} />
        <AudioPlayer audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/332stronski.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/332stronski.mp4`]}
          audioImage={assetQuery.audioImage.childImageSharp.fluid} audioDescription={audio} size={2} dark />
      </BackgroundAnimation>
    </BlockContainer>
  );
};

export default Slide;
