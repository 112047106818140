import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useStaticQuery, graphql } from 'gatsby';
import VideoMP4 from 'src/assets/videos/chapter_3/sub_2/3-2-2-bg.mp4';
import Video from 'src/assets/videos/chapter_3/sub_2/3-2-2-bg.webm';
import BackgroundAnimation from 'src/components/desktop/backgroundAnimation/backgroundAnimation';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import { getLangFromPath } from 'src/utils/index.js';
import { SlideLarge } from '../../_styles';
import { mediaServer } from '../../../../../../globals';


const Slide = ({ query }) => {
  const language = getLangFromPath();

  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_3/sub_2/3-2-2-bg.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  scenelayer1: file(relativePath: { eq: "chapter_3/sub_2/3-2-2-parallax.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  audioImage: file(relativePath: { eq: "chapter_3/sub_2/3-2-2-audio.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  }
  `);

  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
        />

        <Mordo
          scenelayer
          scene={2}
          move={{ x: 10, y: 20 }}
          background={{ image: assetQuery.scenelayer1.childImageSharp.fluid }}
          opacity={1}
        />
        <Mordo
          scenelayer
          scene={1}
          move={{ x: -20, y: -10 }}
          opacity={1}
        >
          <BackgroundAnimation src={Video} mp4={VideoMP4} responsive />
        </Mordo>
        <Mordo
          flexi
          scene={2}
          move={{ x: -20, y: -10 }}
        >
          <LeftColContent body={query.leftColumn.body} dark audio={[`${mediaServer}/webm/${language.toLowerCase()}/332stronski.webm`, `${mediaServer}/mp4/${language.toLowerCase()}/332stronski.mp4`]} audioImage={assetQuery.audioImage.childImageSharp.fluid} style={{ marginLeft: '2%', paddingBottom: '5%' }} />
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
