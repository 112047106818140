import React from 'react';
import { AnimationContainer } from './styles';


const BackgroundAnimation = ({
  children, src, mp4, responsive, noloop,
}) => (
  <AnimationContainer responsive={responsive}>
    { noloop ? (
      <video autoPlay playsInline muted>
        <source src={src} type="video/webm" />
        <source src={mp4} type="video/mp4" />
      </video>
    ) : (
      <video autoPlay playsInline loop muted>
        <source src={src} type="video/webm" />
        <source src={mp4} type="video/mp4" />
      </video>
    )}

    {children}
  </AnimationContainer>
);

export default BackgroundAnimation;
