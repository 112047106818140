import React from 'react';
import { useInView } from 'react-intersection-observer';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Hyperlink from '../../_shared/hyperlink/hyperlink';
import {
  QuoteContainer,
  QuoteMark,
  QuoteText,
  QuoteAuthor,
  QuoteAuthorRole,
  QuoteAuthorContainer,
  Positioner,
} from './styles';

const Quote = ({
  fontColor,
  fontAuthorColor,
  quoteText,
  quoteAuthor,
  quoteAuthorRole,
  fullScreen,
  modalImage,
  whiteMark,
  isSmaller,
  customTreshold = 0.8
}) => {
  const [ref, inView] = useInView({
    threshold: customTreshold,
  });

  return (
    <Positioner fullScreen={fullScreen} ref={ref}>
      <QuoteContainer isSmaller={isSmaller}>
        <QuoteMark whiteMark={whiteMark} visible={inView} />
        <QuoteText tabIndex="0" color={fontColor} visible={inView} isSmaller={isSmaller}>
          <MDXProvider components={{ Hyperlink }}>
            <MDXRenderer modalImage={modalImage}>{quoteText}</MDXRenderer>
          </MDXProvider>
        </QuoteText>
        <QuoteAuthorContainer visible={inView}>
          <QuoteAuthor tabIndex="0" color={fontAuthorColor || fontColor}>
            {quoteAuthor}
          </QuoteAuthor>
          <QuoteAuthorRole tabIndex="0" color={fontColor}>
            {quoteAuthorRole}
          </QuoteAuthorRole>
        </QuoteAuthorContainer>
      </QuoteContainer>
    </Positioner>
  );
};

export default Quote;
