import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useStaticQuery, graphql } from 'gatsby';
import Quote from 'src/components/desktop/quote/quote';
import VideoMP4 from 'src/assets/videos/chapter_3/sub_2/3-2-1-bg.mp4';
import Video from 'src/assets/videos/chapter_3/sub_2/3-2-1-bg.webm';
import BackgroundAnimation from 'src/components/desktop/backgroundAnimation/backgroundAnimation';
import { SlideLarge } from '../../_styles';


const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
  scenelayer1: file(relativePath: { eq: "chapter_3/sub_2/3-2-1-parallax.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  modal: file(relativePath: { eq: "chapter_3/sub_2/3-2-1-modal.png"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  }
  }
  `);
  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ color: 'transparent' }}
        >
          <BackgroundAnimation src={Video} mp4={VideoMP4} responsive />
        </Mordo>

        <Mordo
          scenelayer={{ fromBottom: '-10%' }}
          scene={1}
          move={{ x: 10, y: 20 }}
          background={{ image: assetQuery.scenelayer1.childImageSharp.fluid }}
          opacity={1}
        />
        <Mordo
          flexi
          scene={2}
          move={{ x: -20, y: -10 }}
        >
          <div css={{
            alignSelf: 'left', width: '100%', margin: '0 0 0 -20%',
          }}
          >
            <Quote isSmaller fontColor="white" quoteText={query.allMdx.edges[0].node.body} quoteAuthor={query.allMdx.edges[0].node.frontmatter.author} modalImage={assetQuery.modal.childImageSharp.fixed} customTreshold={0.5} />


          </div>
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
