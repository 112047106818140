import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import { BlockContainer, ChpaterTitle } from '../../_styles';
import Quote from '../../../../../components/mobile/quote/quote';

const Slide = ({ query, title }) => (
  <BlockContainer height="750px">
    <BackgroundPhoto fixed={query.file.childImageSharp.fixed} scale alt="slide-photo">
      <ChpaterTitle marginTop="8rem">{title}</ChpaterTitle>
      <Quote fontColor="white" quoteText={query.mdx.body} />
    </BackgroundPhoto>
  </BlockContainer>
);

export default Slide;
