import styled, { css } from 'styled-components';
import quotePNG from 'images/_shared/quote.png';
import { fontsMobile } from '../../../../../globals';

const ANIMATION_START = css`
  transform: translate3d(0,70px,0);
  opacity: 0;
`;
const ANIMATION_END = css`
  transform: translate3d(0,0,0);
  opacity: 1;
`;


export const Positioner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({ fullScreen }) => fullScreen && '3.3%'};
  background: ${({ isDimmed }) => isDimmed && 'rgba(19,35,46,0.6)'};
`;

export const QuoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
  height: 100%;
`;

export const QuoteMark = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  background-image: ${() => `url(${quotePNG})`};
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(0.1) rotate(359deg);
  transition: all .6s ease-in-out 0.1s;
  filter: ${(props) => (props.whiteMark ? 'brightness(255)' : 'none')};
  ${({ visible }) => visible
    && css`
      transform: scale(1) rotate(0deg);
     `};
`;

export const QuoteText = styled.div`
  color: ${(props) => props.color};
  ${{ ...fontsMobile.quote }}
  text-align: center;
  margin: 1.75rem 0;
  line-height: 1.625rem;
  ${ANIMATION_START}
  transition: all 0.8s cubic-bezier(0.78, 0, 0.235, 1) 0.3s ;
  ${({ visible }) => visible
    && ANIMATION_END}
`;

export const QuoteAuthorContainer = styled.div`
    text-align: center;
    line-height:1.7rem;
    ${ANIMATION_START}
    transition: all 0.8s cubic-bezier(0.78, 0, 0.235, 1) 0.5s ;
    ${({ visible }) => visible
    && ANIMATION_END}
`;

export const QuoteAuthor = styled.div`
  ${{ ...fontsMobile.popup }}
  color: ${(props) => props.color};
  font-weight: 700;
  text-transform: uppercase;
`;

export const QuoteAuthorRole = styled.div`
  ${{ ...fontsMobile.popup }}
  color: ${(props) => props.color};
  font-weight: 400;
  text-transform: uppercase;
`;
