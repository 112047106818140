import React from 'react';
import { useInView } from 'react-intersection-observer';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Hyperlink from '../../_shared/hyperlink/hyperlink';
import {
  QuoteContainer,
  QuoteMark,
  QuoteText,
  QuoteAuthor,
  QuoteAuthorRole,
  QuoteAuthorContainer,
  Positioner,
} from './styles';

const Quote = ({
  fontColor,
  fontAuthorColor,
  quoteText,
  quoteAuthor,
  quoteAuthorRole,
  fullScreen,
  isDimmed,
  containerStyle,
  whiteMark,
}) => {
  const [ref, inView] = useInView({
    threshold: 0.3,
  });

  return (
    <Positioner fullScreen={fullScreen} ref={ref} isDimmed={isDimmed} style={containerStyle}>
      <QuoteContainer>
        <QuoteMark whiteMark={whiteMark} visible={inView} />
        <QuoteText color={fontColor} visible={inView}>
          <MDXProvider components={{ Hyperlink }}>
            <MDXRenderer>{quoteText}</MDXRenderer>
          </MDXProvider>
        </QuoteText>
        <QuoteAuthorContainer visible={inView}>
          <QuoteAuthor color={fontAuthorColor || fontColor}>
            {quoteAuthor}
          </QuoteAuthor>
          <QuoteAuthorRole color={fontColor}>{quoteAuthorRole}</QuoteAuthorRole>
        </QuoteAuthorContainer>
      </QuoteContainer>
    </Positioner>
  );
};

export default Quote;
